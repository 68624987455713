import { maskProductsAmount } from './cart/cart-management.js';

export default function () {
    const countItems = document.querySelector('.detail-amount');

    if (!countItems) return;

    maskProductsAmount('.detail-amount input[type="text"]');

    const inputField = countItems.querySelector('input');

    countItems.querySelector('.plus').addEventListener('click', function () {
        inputField.value = Number(inputField.value) + 1;
    }, false);
    countItems.querySelector('.plus').addEventListener('keydown', function (e) {
        if (e.key === 'Enter') {
            this.click();
        }
    }, false);

    countItems.querySelector('.minus').addEventListener('click', function () {
        if (Number(inputField.value) > 1) {
            inputField.value = Number(inputField.value) - 1;
        }
    }, false);
    countItems.querySelector('.minus').addEventListener('keydown', function (e) {
        if (e.key === 'Enter') {
            this.click();
        }
    }, false);

}
