import showPopup from './modals';
export default function () {
    initShippingPopup();
    initPaymentsPopup();
}

function initShippingPopup() {
    const shippingPopupTrigger = document.querySelector(".shipping-cost-button");
    if (!shippingPopupTrigger) return;
    const shippingPopupBody = document.querySelector(".shipping-popup-contents");
    shippingPopupBody.remove();

    shippingPopupTrigger.addEventListener("click", () => {
        showPopup({
            header: 'Koszty Dostawy',
            body: shippingPopupBody.innerHTML,
            overloadClass: 'user-custom-content'
        });
    });
}

function initPaymentsPopup() {
    const paymentsPopupTrigger = document.querySelector(".payment-methods-button");
    if (!paymentsPopupTrigger) return;
    const paymentsPopupBody = document.querySelector(".payment-popup-contents");
    paymentsPopupBody.remove();

    paymentsPopupTrigger.addEventListener("click", () => {
        showPopup({
            header: 'Metody płatności',
            body: paymentsPopupBody.innerHTML,
            overloadClass: 'user-custom-content'
        });
    });
}