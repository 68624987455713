import Cookies from 'js-cookie';
import { on } from 'delegated-events';
export default function () {

    on('click', '.view-list', function () {
        document.querySelector(".products-list").classList.add("list-view");
        Cookies.set('list_view', '1', { expires: 365 });
    });

    on('click', '.view-grid', function () {
        document.querySelector(".products-list").classList.remove("list-view");
        Cookies.remove('list_view');
    });
}